<template>
    <div class="container">
        <div v-if="importedFiles.length" class="imported-files">
            <h3>Korábban importált fájlok:</h3>
            <ul>
                <li v-for="file in importedFiles" :key="file.id">
                    {{ file.file_name }} ({{ file.import_date }})
                </li>
            </ul>
        </div>
        <button class="upload-btn" @click="uploadFile">XLS fájl feltöltése</button>
        <input type="file" ref="fileInput" @change="handleFile" accept=".xls, .xlsx" class="file-input" />
        {{ message }}

        <!-- Megjeleníti a kiválasztott fájl nevét, ha van ilyen -->
        <div v-if="selectedFileName" class="selected-file">
            Kiválasztott fájl: {{ selectedFileName }}
            <button v-if="!isFilePreviouslyImported" class="submit-btn" @click="submitFile">Beküldés</button>
        </div>

        <!-- Megjeleníti a dátum információt, ha van ilyen -->
        <div v-if="dateInfo" class="date-info">
            Dátum: {{ dateInfo }}
        </div>

        <!-- Megjeleníti az importált adatokat, ha vannak ilyenek -->
        <div v-if="transformedData.length" class="imported-data">
            <h3>Importált adatok:</h3>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Típus</th>
                        <th>Név</th>
                        <th>Mennyiség</th>
                        <th>Mennyiség egység</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in transformedData" :key="row[1]">
                        <td>{{ row[10] }}</td>
                        <td>{{ row[13] }}</td>
                        <td>{{ row[18] }}</td>
                        <td>{{ row[17] }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedFileName: null,
            importedData: [],
            dateInfo: null,
            importedFiles: [],
            message: null
        };
    },
    computed: {
        transformedData() {
            return this.importedData.map(row => {
                if (row[10] === "213") {
                    row[10] = "Munkagép";
                } else if (row[10] === "2211") {
                    row[10] = "Készlet";
                } else if (row[10] === "2161") {
                    row[10] = "Munkagép";  // Ide írd, hogy mit jelent a 2161 kód
                }
                return row;
            });
        }
    },

    created() {
        this.fetchImportedFiles();
    },
    methods: {
        async fetchImportedFiles() {
            try {
                let response = await this.$axios.get('/api/getImportedFiles');
                this.importedFiles = response.data;
            } catch (error) {
                console.error("Hiba az importált fájlok lekérésekor:", error);
            }
        },
        uploadFile() {
            this.$refs.fileInput.click();
        },
        handleFile(event) {
            const file = event.target.files[0];
            if (file && (file.name.endsWith('.xls') || file.name.endsWith('.xlsx'))) {
                this.selectedFileName = file.name;
                this.isFilePreviouslyImported = this.importedFiles.some(
                    importedFile => importedFile.file_name === file.name
                );
                if (this.isFilePreviouslyImported) {
                    this.message = "A kiválasztott fájl már importálva lett. Válassz másikat.";
                }
            } else {
                alert('Kérlek, válassz egy érvényes XLS vagy XLSX fájlt.');
            }
        },

        async submitFile() {
            try {
                await this.$axios.get('/sanctum/csrf-cookie');

                let formData = new FormData();
                formData.append('file', this.$refs.fileInput.files[0]);

                let response = await this.$axios.post('/api/import', formData);
                this.dateInfo = response.data.dateInfo;
                this.importedData = response.data.data;
                this.message = response.data.message;
                this.importedFiles = response.data.importedFiles;
                console.log("Adatok sikeresen importálva!");
            } catch (error) {
                console.error("Hiba a fájl feltöltésekor:", error);
            }
        }
    }
}
</script>

<style scoped>
.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.upload-btn {
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.file-input {
    display: none;
}

.selected-file {
    margin-top: 20px;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 4px;
}

.submit-btn {
    padding: 5px 10px;
    background-color: #2ecc71;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.imported-data {
    margin-top: 30px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 10px;
    border: 1px solid #e0e0e0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f7f7f7;
}

.table-hover tbody tr:hover {
    background-color: #eef5ff;
}

.date-info {
    margin-top: 20px;
    font-weight: bold;
}
</style>
