<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="newItem">Új elem hozzáadása</label>
                    <input type="text" class="form-control" id="newItem" v-model="newItem"
                        placeholder="Adj meg egy új elemet">
                </div>
                <button class="btn btn-primary" @click="addNewItem">Hozzáad</button>
                <button class="btn btn-secondary mt-3" @click="updateInventoryItems">Frissítés</button> <!-- Új gomb -->
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Megnevezés - Készlet - Telephely</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td>{{ item.name }} - {{ item.stock }} - {{ item.location }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            newItem: '',
            items: [],
        }
    },
    methods: {
        async addNewItem() {
            try {
                await axios.get('/sanctum/csrf-cookie');
                const response = await axios.post('/api/add-new-item', { item: this.newItem });

                if (response.data.success) {
                    this.newItem = '';
                    this.fetchItems(); // Frissítse az elemeket a hozzáadás után
                    alert('Az elem hozzáadása sikeres volt!');
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                alert('Hiba történt az elem hozzáadása közben.');
            }
        },
        async fetchItems() {
            try {
                await axios.get('/sanctum/csrf-cookie');
                const response = await axios.get('/api/items');
                this.items = response.data.sort((a, b) => a.name.localeCompare(b.name));
            } catch (error) {
                console.error(error);
            }
        },
        async updateInventoryItems() {
            try {
                await axios.get('/sanctum/csrf-cookie');
                const formattedItems = this.items.map(item => `${item.name} - (${item.stock})`);
                const response = await axios.post('/api/addNewInventoryItems', { items: formattedItems });

                if (response.data.success) {
                    alert('Az elemek frissítése sikeres volt!');
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                console.error(error);
                alert('Hiba történt az elemek frissítése közben.');
            }
        },
    },
    created() {
        this.fetchItems(); // Fetch items when component is created
    },
}
</script>
