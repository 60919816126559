<template>
  <div class="container">
    <div class="row">
      <h2>Munkanaplók</h2>
      <h3>Projektek listája:</h3>
      <div v-for="(project, idx) in uniqueProjects" :key="idx">
        <button class="project-button" @click="selectProject(project)">
          {{ project }} - {{ formatDate(latestDateForProjects[project]) }}
        </button>
      </div>

      <p v-if="error">{{ error }}</p>
      <p>Válaszd ki a munkanaplót:</p>
      <!-- Legördülő menü az eredmények választásához -->
      <select v-model="selectedResult" class="form-control mb-3">
        <option v-for="(result, index) in filteredDropdownResults" :key="index" :value="result">
          {{ formatDate(result.time) }} | {{ result.field_Munkafolyamat }} | {{ result.field_Projekt }}
        </option>
      </select>

      <!-- Kiválasztott eredmény megjelenítése -->
      <div v-if="selectedResult">
        <div class="border-bottom pb-2 mb-2">
          <h2>Dátum:</h2>
          {{ selectedResult.time }}
        </div>
        <div class="border-bottom pb-2 mb-2">
          <h2>Projekt:</h2>
          {{ selectedResult.field_Projekt }}
        </div>
        <div class="border-bottom pb-2 mb-2">
          <h2>Munkafolyamat:</h2>
          {{ selectedResult.field_Munkafolyamat }}
        </div>

        <div class="border-bottom pb-2 mb-2">
          <h2>Felhasznált készlet:</h2>
          <ul>
            <li v-for="(item, idx) in selectedResult.field_Készlet" :key="idx">
              {{ item.material }} - {{ item.quantity }}
            </li>
          </ul>
        </div>

        <div class="border-bottom pb-2 mb-2">
          <h2>Elhasznált anyagok:</h2>
          <ul>
            <li v-for="(item, idx) in selectedResult.field_Elhasznált" :key="idx">
              {{ item.material }} - {{ item.quantity }}
            </li>
          </ul>
        </div>

        <div class="border-bottom pb-2 mb-2">
          <h2>Dolgozók:</h2>
          <ul>
            <li v-for="(employeeObj, idx) in selectedResult.field_Ottdolgozok" :key="idx">
              {{ employeeObj.employee }}
            </li>
          </ul>
        </div>

        <div class="border-bottom pb-2 mb-2">
          <h2>Megjegyzés:</h2> 
          {{ selectedResult.field_Megjegyzés }}
        </div>

        <div class="border-bottom pb-2 mb-2">
          <h2>Képek:</h2>
          <ul>
            <li v-for="(imageJson, idx) in selectedResult.field_images" :key="idx">
              <a :href="imageJson.url" target="_blank">
                <img :src="JSON.parse(imageJson.sizes).s.url" alt="Product Image" class="thumbnail-image">
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>



<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import * as XLSX from 'xlsx';

export default {
  setup() {
    const results = ref({ data: [] });
    const error = ref('');
    const searchText = ref('');
    const sortField = ref('');
    const sortAscending = ref(true);
    const selectedResult = ref(null);
    const selectedProject = ref(null);
    const latestResult = ref(null);

    const fetchResults = async () => {
      try {
        await axios.get('/sanctum/csrf-cookie');
        const response = await axios.get(`/api/form/results`);
        results.value = response.data;

        // Set latest result
        if (response.data.length > 0) {
          latestResult.value = response.data[0];
          selectedResult.value = response.data[0];
        }

        // If a project is selected, set the latest result for that project
        if (selectedProject.value) {
          const latestForSelectedProject = response.data.find(res => res.field_Projekt === selectedProject.value);
          if (latestForSelectedProject) {
            selectedResult.value = latestForSelectedProject;
          }
        }

      } catch (error) {
        console.error('An error occurred:', error);
        error.value = 'Hiba történt az adatok betöltése közben.';
      }
    };



    const changeSort = (field) => {
      if (sortField.value === field) {
        sortAscending.value = !sortAscending.value;
      } else {
        sortField.value = field;
        sortAscending.value = true;
      }
    };

    const exportToXLS = () => {
      const transformedData = results.value.data.map(result => {
        return {
          ...result,
          field_Készlet: result.field_Készlet.map(item => `${item.material} - ${item.quantity}`).join('\n'),
          field_Elhasznált: result.field_Elhasznált.map(item => `${item.material} - ${item.quantity}`).join('\n'),
          field_Ottdolgozok: result.field_Ottdolgozok.map(employeeObj => employeeObj.employee).join('\n'),
          field_images: result.field_images.map(imageJson => JSON.parse(imageJson.sizes).s.url).join('\n')
        };
      });

      // Convert to array of arrays
      const aoa = transformedData.map(item => Object.values(item));
      // Add headers manually
      aoa.unshift(["#", "Dátum", "Projekt", "Munkafolyamat", "Felhasznált készlet", "Elhasznált anyagok", "Dolgozók", "Megjegyzés", "Képek"]);

      let ws = XLSX.utils.aoa_to_sheet(aoa);

      // Setting the wrap text for specific columns
      ['D', 'E', 'F', 'I'].forEach(col => {
        for (let row = 1; row <= aoa.length; row++) {
          if (!ws[`${col}${row}`]) continue;
          if (!ws[`${col}${row}`].s) ws[`${col}${row}`].s = {};
          ws[`${col}${row}`].s.alignment = { wrapText: true };
        }
      });

      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Munkanaplok");
      XLSX.writeFile(wb, "munkanaplok.xlsx");
    };

    const uniqueProjects = computed(() => {
      const allProjects = results.value.data.map(result => result.field_Projekt);
      return [...new Set(allProjects)];
    });

    const latestDateForProjects = computed(() => {
      let dateMap = {};
      results.value.data.forEach(result => {
        if (!dateMap[result.field_Projekt] || new Date(result.time) > new Date(dateMap[result.field_Projekt])) {
          dateMap[result.field_Projekt] = result.time;
        }
      });
      return dateMap;
    });

    const selectProject = (project) => {
      selectedProject.value = project;
      const latestForSelectedProject = results.value.data.find(res => res.field_Projekt === project);
      if (latestForSelectedProject) {
        selectedResult.value = latestForSelectedProject;
      } else {
        selectedResult.value = null;
      }
    };

    const filteredDropdownResults = computed(() => {
      if (!selectedProject.value) return results.value.data;
      return results.value.data.filter(res => res.field_Projekt === selectedProject.value);
    });


    const formatDate = (dateTimeString) => {
      const dateObj = new Date(dateTimeString);
      return `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
    };

    const filteredResults = computed(() => {
      let data = [...results.value.data];

      if (searchText.value) {
        const search = searchText.value.toLowerCase();

        data = data.filter(result => {
          return ['time', 'field_Projekt', 'field_Munkafolyamat', 'field_Készlet', 'field_Elhasznált', 'field_Ottdolgozok', 'field_Megjegyzés'].some(key => {
            const value = result[key];

            if (typeof value === 'string') {
              return value.toLowerCase().includes(search);
            } else if (Array.isArray(value)) {
              return value.some(item => {
                if (typeof item === 'string') {
                  return item.toLowerCase().includes(search);
                } else if (typeof item === 'object') {
                  return Object.values(item).some(v => v && v.toString().toLowerCase().includes(search));
                }
                return false;
              });
            }
            return false;
          });
        });
      }

      if (sortField.value) {
        data.sort((a, b) => {
          if (a[sortField.value] < b[sortField.value]) return sortAscending.value ? -1 : 1;
          if (a[sortField.value] > b[sortField.value]) return sortAscending.value ? 1 : -1;
          return 0;
        });
      }

      return data;
    });

    onMounted(fetchResults);

    return { results, error, searchText, sortField, sortAscending, changeSort, exportToXLS, filteredResults, selectedResult, uniqueProjects, latestDateForProjects, formatDate, selectProject, selectedProject, filteredDropdownResults };
  },
};
</script>


<style scoped>
h2 {
  margin: 20px 0;
  color: #00558E;
}

.thumbnail-image {
  max-width: 100px;
  height: auto;
  border: 1px solid #ccc;
  margin-right: 5px;
}

table {
  border-collapse: collapse;
}

th {
  color: #00558E;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.export-link {
  margin-left: 10px;
  color: #19A043;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
}

.export-link:hover {
  text-decoration: underline;
}

.mb-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-container {
  display: flex;
  align-items: center;
}

.refresh-button {
  width: 80px;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Responsive adjustments */

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  h2 {
    font-size: 18px;
    margin: 10px 0;
  }

  .export-link {
    font-size: 14px;
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .form-control {
    width: 100%;
  }

  .mb-3 {
    flex-direction: column;
    align-items: start;
  }

  .export-container {
    margin-top: 10px;
  }

  th,
  td {
    padding: 8px 10px;
  }

  .project-button {
    display: inline-block;
    margin: 5px 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    background-color: #00558E;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: calc(100% - 20px);
    /* Full width minus margin */
  }


  .project-button:hover {
    background-color: #003f63;
  }

  .item-separator {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
</style>
