<template>
  <div class="container">
    <div class="mb-3">
      <div class="row">
      <h2>Készletek</h2>
      <button @click="updateTools" class="btn btn-primary refresh-button">Frissítés</button>
      </div>
      <div class="export-container">
        <a href="#" @click="exportToXLS" class="text-right export-link">Exportálás XLS-be</a>
      </div>
    </div>
      <div class="row mb-3">
        <input v-model="searchText" class="form-control" type="text" placeholder="Keresés...">
      </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" @click="changeSort('article_number')">
            Cikk
            <span v-if="sortField === 'article_number'" class="sort-indicator">
              <span v-if="sortAscending">▲</span>
              <span v-else>▼</span>
            </span>
          </th>
          <th scope="col" @click="changeSort('name')">
            Megnevezés
            <span v-if="sortField === 'name'" class="sort-indicator">
              <span v-if="sortAscending">▲</span>
              <span v-else>▼</span>
            </span>
          </th>
          <th scope="col" @click="changeSort('stock')">
            Készlet
            <span v-if="sortField === 'stock'" class="sort-indicator">
              <span v-if="sortAscending">▲</span>
              <span v-else>▼</span>
            </span>
          </th>
          <th scope="col" @click="changeSort('location')">
            Telephely
            <span v-if="sortField === 'location'" class="sort-indicator">
              <span v-if="sortAscending">▲</span>
              <span v-else>▼</span>
            </span>
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in filteredItems" :key="index">
          <td>
            <input v-model="item.article_number" class="form-control" type="text">
          </td>
          <td>
            <input v-model="item.name" class="form-control" type="text">
          </td>
          <td>
            <input v-model="item.stock" class="form-control" type="number">
          </td>
          <td>
            <input v-model="item.location" class="form-control" type="text">
          </td>
          <td>
            <button v-if="itemChanged(index, item.originalIndex)" @click="saveItem(index, item.originalIndex)"
              class="btn btn-primary">Mentés</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="add-item-container">
      <button @click="addItem" class="add-item-button">+</button>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';

export default {
  name: 'EditableTable',
  data() {
    return {
      items: [],
      originalItems: [], // Array to store original items
      sortField: '',
      sortAscending: true,
      filterField: '',
      filterValue: '',
      searchText: '',
    };
  },
  async created() {
    try {
      await axios.get('/sanctum/csrf-cookie');
      const response = await axios.get('/api/items');
      this.items = response.data.map((item, index) => ({ ...item, originalIndex: index }));
      this.originalItems = JSON.parse(JSON.stringify(this.items)); // Deep copy of items
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    exportToXLS() {
      let ws = XLSX.utils.json_to_sheet(this.items);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Keszletek");
      XLSX.writeFile(wb, "keszletek.xlsx");
    },

    addItem() {
      const newItem = {
        article_number: '',
        name: '',
        stock: '',
        location: '',
        originalIndex: this.items.length,
      };
      this.items.push(newItem);
      this.originalItems.push(JSON.parse(JSON.stringify(newItem))); // Add copy of new item to originalItems as well
    },

    async saveItem(index, originalIndex) {
      console.log('Mentve:', this.items[originalIndex]);
      try {
        await axios.get('/sanctum/csrf-cookie');
        const item = this.items[originalIndex];
        if (item.id) {
          await axios.put(`/api/items/${item.id}`, item);
        } else {
          const response = await axios.post('/api/items', item);
          this.items[originalIndex].id = response.data.id;
        }
        // After saving item, update the original item
        this.originalItems[originalIndex] = JSON.parse(JSON.stringify(this.items[originalIndex]));
      } catch (error) {
        console.error(error);
      }
    },

    itemChanged(index, originalIndex) { // Method to check if item has changed
      return JSON.stringify(this.items[originalIndex]) !== JSON.stringify(this.originalItems[originalIndex]);
    },

    changeSort(field) {
      if (this.sortField === field) {
        this.sortAscending = !this.sortAscending;
      } else {
        this.sortField = field;
        this.sortAscending = true;
      }
    },
  },
  computed: {
    filteredItems() {
      let items = [...this.items];  // Create a copy of items array

      // Apply search text
      if (this.searchText) {
        items = items.filter(item =>
          item.article_number.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.location.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }

      // Sort items
      if (this.sortField) {
        items = items.sort((a, b) => {
          if (a[this.sortField] < b[this.sortField]) return this.sortAscending ? -1 : 1;
          if (a[this.sortField] > b[this.sortField]) return this.sortAscending ? 1 : -1;
          return 0;
        });
      }

      return items;
    },
  },

};
</script>

<style scoped>
.add-item-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  padding-bottom: 80px;
}

.add-item-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: white;
  background-color: #19A043;
  border: none;
}

.sort-indicator {
  color: #00558E;
}

.container {
  padding-top: 2em;
}

.btn-primary {
  background-color: #19A043;
  border-color: #19A043;
}

th {
  color: #00558E;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.table th,
.table td {
  border: 0;
}

.export-link {
  margin-left: 10px;
  color: #19A043;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
  /* This is to align it with the button vertically */
}

.export-link:hover {
  text-decoration: underline;
}
.mb-3 {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Elrendezzük az elemeket a konténer két végén */
}
.export-container {
  display: flex;
  align-items: center;
}
.refresh-button {
  width: 80px;
  margin-bottom: 10px;
  font-size: 14px;        /* Reduce the font-size */
  /*padding: 5px 10px;     /* Adjust the padding */
}

</style>
  
