<template>
    <div class="container">
        <div class="starter-template">
            <div class="row justify-content-center mb-4">
                <div class="col-12 text-center">
                    <h3>Munkanapló vezérlőpult</h3>
                </div>
            </div>

            <div class="row">
                <csempe-ikonnal-vue class="csempe col-12 col-md-4" svg='clipboard-outline.svg' text='Munkanaplók' link='/eredmeny' />
                <csempe-ikonnal-vue class="csempe col-12 col-md-4" svg='file-tray-full-outline.svg' text='Eszközök' link='/Keszlet' />
                <csempe-ikonnal-vue class="csempe col-12 col-md-4" svg='construct-outline.svg' text='Munkagépek' link='/ToolList' />
            </div>

            <div class="row">
                <csempe-ikonnal-vue class="csempe col-12 col-md-4" svg='business-outline.svg' text='Projektek' link='/Projektek' />
                <csempe-ikonnal-vue class="csempe col-12 col-md-4" svg='people-outline.svg' text='Dolgozók' link='/employees' />
                <csempe-ikonnal-vue class="csempe col-12 col-md-4" svg='open-outline.svg' text='XLS importálása' link='/xls' />
            </div>
        </div>
    </div>
</template>


<script>
import CsempeIkonnalVue from '../components/Csempe.vue'

export default {
    name: "Home",
    data() {
        return {
            //
        }
    },
    created() {
    },
    methods: {},
    components: {
        CsempeIkonnalVue
    }
}
</script>

<style>
.starter-template {
    text-align: center;
}

span.lower-text {
    color: #ffc300;
    font-size: 25px;
    display: block;
}

.hover-div {
    padding: 20px 20px;
    text-align: center;
    min-height: 240px;
    border-top: 1px solid #f8f8f8;
    background: #f8f8f8;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    margin: 10px 0px;
}

.hover-div:hover {
    transform: translateY(-20px);
    box-shadow: 0 22px 43px rgba(0, 0, 0, 0.32);
    cursor: pointer;
    border-radius: 5px;
}

.csempe {
    margin-bottom: 20px;
}
</style>
