<template>
    <div class="card-style" @click="clickeMethod(link)">
       <div class="p-5">
           <img :src = "'img/' + svg" width="48" height="48" alt=""/>
           <p class="text-center card-text"> {{ text }} </p>
       </div>
   </div>
 </template>
 
 <script>
 export default {
     props: ['svg', 'text', 'link'],
     methods: {
         clickeMethod(link) {
             if (link === '/logout') {
                 this.logout();
             } else {
                 this.$router.push(link);
             }
         },
         logout() {
             this.$axios.get('/sanctum/csrf-cookie').then(response => {
                 this.$axios.post('/api/logout')
                 .then(response => {
                     if(response.data.success) {
                         window.location.href = "/"
                     } else {
                         console.log(response);
                     }
                 })
                 .catch(function (error) {
                     console.error(error);
                 });
             })
         }
     }
 }
 </script>
 
 
 <style>
.card-style {
    background-color: #fff;
    background-image: url('/img/background.jpg'); /* Háttérkép hozzáadása */
    background-size: cover; /* Kép méretének beállítása */
    background-position: center; /* Kép középre igazítása */
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px 0; /* Vízszintes margót eltávolítottam */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.card-style:hover {
    transform: scale(1.02);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.p-5 {
    padding: 20px;
    text-align: center;
}

.card-text {
    color: #00558E;
    font-size: 18px;
    text-align: center;
    padding-top: 10px;
}
</style>
