<template>
  <div class="container">
    <div class="row">
      <h2>Munkanaplók</h2>
      <p v-if="error">{{ error }}</p>
      <div class="export-container">
        <a href="#" @click="exportToXLS" class="text-right export-link">Exportálás XLS-be</a>
      </div>
      <div class="mb-3">
        <input v-model="searchText" class="form-control" type="text" placeholder="Keresés...">
      </div>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" @click="changeSort('time')">Dátum</th>
            <th scope="col" @click="changeSort('field_Projekt')">Projekt</th>
            <th scope="col" @click="changeSort('field_Munkafolyamat')">Munkafolymat</th>
            <th scope="col" @click="changeSort('field_Készlet')">Felhasznált készlet</th>
            <th scope="col" @click="changeSort('field_Elhasznált')">Elhasznált anyagok</th>
            <th scope="col" @click="changeSort('field_Ottdolgozok')">Dolgozók</th>
            <th scope="col" @click="changeSort('field_Megjegyzés')">Megjegyzés</th>
            <th scope="col" @click="changeSort('field_images')">Képek</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in filteredResults" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ result.time }}</td>
            <td>{{ result.field_Projekt }}</td>
            <td>{{ result.field_Munkafolyamat }}</td>
            <td>
              <ul>
                <li v-for="(item, idx) in result.field_Készlet" :key="idx">
                  {{ item.material }} - {{ item.quantity }}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li v-for="(item, idx) in result.field_Elhasznált" :key="idx">
                  {{ item.material }} - {{ item.quantity }}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li v-for="(employeeObj, idx) in result.field_Ottdolgozok" :key="idx">
                  {{ employeeObj.employee }}
                </li>
              </ul>
            </td>
            <td>{{ result.field_Megjegyzés }}</td>
            <td>
              <ul>
                <li v-for="(imageJson, idx) in result.field_images" :key="idx">
                  <a :href="imageJson.url" target="_blank">
                    <img :src="JSON.parse(imageJson.sizes).s.url" alt="Product Image" class="thumbnail-image">
                  </a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import * as XLSX from 'xlsx';

export default {
  setup() {
    const results = ref({ data: [] });
    const error = ref('');
    const searchText = ref('');
    const sortField = ref('');
    const sortAscending = ref(true);

    const fetchResults = async () => {
      try {
        await axios.get('/sanctum/csrf-cookie'); // Fetch CSRF token
        //console.log("FECCSEL");
        // Frissítő API végpont hívása
        await axios.get('/api/form/fullresults');

        // Eredeti kód
        const response = await axios.get(`/api/form/results`);
        results.value = response.data;
      } catch (error) {
        console.error('Hiba történt:', error);
        error.value = 'Hiba történt az adatok betöltése közben.';
      }
    };


    const changeSort = (field) => {
      if (sortField.value === field) {
        sortAscending.value = !sortAscending.value;
      } else {
        sortField.value = field;
        sortAscending.value = true;
      }
    };

    const exportToXLS = () => {
      const transformedData = results.value.data.map(result => {
        return {
          ...result,
          field_Készlet: result.field_Készlet.map(item => `${item.material} - ${item.quantity}`).join('\n'),
          field_Elhasznált: result.field_Elhasznált.map(item => `${item.material} - ${item.quantity}`).join('\n'),
          field_Ottdolgozok: result.field_Ottdolgozok.map(employeeObj => employeeObj.employee).join('\n'),
          field_images: result.field_images.map(imageJson => JSON.parse(imageJson.sizes).s.url).join('\n')
        };
      });

      // Convert to array of arrays
      const aoa = transformedData.map(item => Object.values(item));
      // Add headers manually
      aoa.unshift(["#", "Dátum", "Projekt", "Munkafolyamat", "Felhasznált készlet", "Elhasznált anyagok", "Dolgozók", "Megjegyzés", "Képek"]);

      let ws = XLSX.utils.aoa_to_sheet(aoa);

      // Setting the wrap text for specific columns
      ['D', 'E', 'F', 'I'].forEach(col => {
        for (let row = 1; row <= aoa.length; row++) {
          if (!ws[`${col}${row}`]) continue;
          if (!ws[`${col}${row}`].s) ws[`${col}${row}`].s = {};
          ws[`${col}${row}`].s.alignment = { wrapText: true };
        }
      });

      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Munkanaplok");
      XLSX.writeFile(wb, "munkanaplok.xlsx");
    };




    const filteredResults = computed(() => {
      let data = [...results.value.data];

      if (searchText.value) {
        const search = searchText.value.toLowerCase();

        data = data.filter(result => {
          return ['time', 'field_Projekt', 'field_Munkafolyamat', 'field_Készlet', 'field_Elhasznált', 'field_Ottdolgozok', 'field_Megjegyzés'].some(key => {
            const value = result[key];

            if (typeof value === 'string') {
              return value.toLowerCase().includes(search);
            } else if (Array.isArray(value)) {
              return value.some(item => {
                if (typeof item === 'string') {
                  return item.toLowerCase().includes(search);
                } else if (typeof item === 'object') {
                  return Object.values(item).some(v => v && v.toString().toLowerCase().includes(search));
                }
                return false;
              });
            }
            return false;
          });
        });
      }

      if (sortField.value) {
        data.sort((a, b) => {
          if (a[sortField.value] < b[sortField.value]) return sortAscending.value ? -1 : 1;
          if (a[sortField.value] > b[sortField.value]) return sortAscending.value ? 1 : -1;
          return 0;
        });
      }

      return data;
    });

    onMounted(fetchResults);

    return { results, error, searchText, sortField, sortAscending, changeSort, exportToXLS, filteredResults };
  },
};
</script>


<style scoped>
h2 {
  margin: 20px 0;
}

.thumbnail-image {
  max-width: 100px;
  height: auto;
  border: 1px solid #ccc;
  margin-right: 5px;
}

table {
  border-collapse: collapse;
}

th {
  color: #00558E;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.export-link {
  margin-left: 10px;
  color: #19A043;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
}

.export-link:hover {
  text-decoration: underline;
}

.mb-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-container {
  display: flex;
  align-items: center;
}

.refresh-button {
  width: 80px;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Responsive adjustments */

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  h2 {
    font-size: 18px;
    margin: 10px 0;
  }

  .export-link {
    font-size: 14px;
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .form-control {
    width: 100%;
  }

  .mb-3 {
    flex-direction: column;
    align-items: start;
  }

  .export-container {
    margin-top: 10px;
  }

  th,
  td {
    padding: 8px 10px;
  }
}
</style>