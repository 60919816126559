<template>
    <div class="modal-backdrop show" v-if="show" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Projekt lezárása</h5>
            <button type="button" class="btn-close" @click="cancel"></button>
          </div>
          <div class="modal-body">
            <h3>Biztosan lezárod a projektet?</h3>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="cancel">Mégse</button>
            <button type="button" class="btn btn-danger" @click="confirm">Lezárom</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['show'],
    methods: {
      cancel() {
        this.$emit('update:show', false);
      },
      confirm() {
        this.$emit('confirm');
      },
    },
  };
  </script>
  
  <style scoped>
.modal-backdrop.show {
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

}

.modal-dialog {
  width: 100%;
  max-width: 500px;
  margin: 1.75rem auto;
}

.modal-content {
  background-color: #ffffff;
  border: 1px solid #000000; 
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem #000000;
  color: #000;
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #000;
}

.btn-close {
  background: none;
  border: none;
  cursor: pointer;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  background-color: #f8f9fa;
  justify-content: flex-end;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  border: 1px solid #000000;
  border-radius: .25rem;
}

.btn-secondary {
  color: #ffffff; /* ez volt: #000000 */
  background-color: #6c757d;
  border-color: #6c757d;
}

</style>
