<template>
  <div class="container">
    <div class="row">
      <h2>Értékesítések</h2>
      <h3>Meglátogatott emberek listája:</h3>
      <div v-for="(report, index) in uniqueVisits" :key="index">
        <button class="project-button" @click="selectVisit(report, index)">
          {{ report.nameLocation }} - {{ formatDate(report.date) }}
        </button>
        <div v-if="selectedVisitIndex === index">
          <table class="table">
            <thead>
              <tr>
                <th class="col-2">Dátum</th>
                <th class="col-7">Eladva</th>
                <th class="col-3">Képek</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(result, index) in filteredResults" :key="index">
                <td class="col-2">{{ formatDate(result.form_created_at) }}</td>
                <td class="col-7">
                  <div style="word-wrap: break-word; max-width: 100%;">
                    {{ result.eladva }}
                  </div>
                </td>
                <td class="col-3">
                  <div v-if="result.sales_images">
                    <ul>
                      <li v-for="image in JSON.parse(result.sales_images)" :key="image.url">
                        <a :href="image.url" target="_blank">
                          <img :src="image.url" alt="Image" class="img-thumbnail">
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="version-number" style="text-align: right;color: #999;margin-top: 20px;">v20240805</div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      loading: false,
      message: '',
      valasz: [],
      selectedVisit: null,
      selectedVisitIndex: -1,  // Index az aktív gomb követésére
      filteredResults: [],
      intervalID: null  // Egy változó az intervallum azonosítójának tárolására
    };
  },
  computed: {
    uniqueVisits() {
      const visits = new Map();
      this.valasz.forEach(v => {
        const key = v.ki;
        if (!visits.has(key)) {
          visits.set(key, { nameLocation: v.ki, date: v.form_created_at });
        }
      });
      // Az egyedi látogatások listájának időrendben csökkenő rendezése
      return Array.from(visits.values()).sort((a, b) => moment(b.date) - moment(a.date));
    }
  },
  methods: {
    formatDate(dateTimeString) {
      const dateObj = moment(dateTimeString, "YYYY-MM-DD HH:mm:ss");
      if (!dateObj.isValid()) {
        return 'Invalid date'; // Vagy valami megfelelőbb helyettesítő szöveg
      }
      return dateObj.format("YYYY-MM-DD");
    },
    async fetchCSRF() {
      try {
        await axios.get('/sanctum/csrf-cookie');
      } catch (error) {
        console.error('CSRF token fetch error:', error);
      }
    },
    async CallIndex() {
      this.loading = true;
      this.message = '';
      try {
        await this.fetchCSRF();
        const response = await axios.get('/api/sales-reports');

        // Az újonnan érkezett adatok időrend szerint rendezése és érvénytelen dátumok kiszűrése
        this.valasz = response.data.filter(report => {
          const date = moment(report.form_created_at, "YYYY-MM-DD HH:mm:ss");
          const isValid = date.isValid();
          if (!isValid) {
            console.warn('Invalid date found:', report.form_created_at);
          }
          return isValid; // Érvénytelen dátumok kiszűrése
        }).sort((a, b) => moment(b.form_created_at) - moment(a.form_created_at));

        if (this.selectedVisit) {
          this.selectVisit(this.selectedVisit, this.selectedVisitIndex);
        } else {
          this.message = "Adatok sikeresen betöltve!";
        }
      } catch (error) {
        this.message = error.response?.data?.message || 'Ismeretlen hiba történt.';
      } finally {
        this.loading = false;
      }
    },
    selectVisit(visit, index) {
      this.selectedVisit = visit;
      this.selectedVisitIndex = index; // Index beállítása
      this.filteredResults = this.valasz.filter(item => item.ki === visit.nameLocation);
    }
  },
  mounted() {
    this.CallIndex();  // Az alkalmazás indulásakor azonnal meghívja az adatlekérést
    this.intervalID = setInterval(this.CallIndex, 10000);  // Beállít egy 10 másodperces intervallumot
  },
  beforeDestroy() {
    clearInterval(this.intervalID);  // Megszünteti az intervallumot, amikor a komponens elpusztul
  }
}
</script>

<style scoped>
.project-button {
  display: inline-block;
  margin: 5px 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  background-color: #00558E;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: calc(100% - 20px);
}

.project-button:hover {
  background-color: #003f63;
}

h2 {
  margin: 20px 0;
  color: #00558E;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  margin-right: 5px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  color: #00558E;
  padding: 8px 10px;
}

th {
  font-weight: bold;
  position: sticky;
  top: 0;
}

td div {
  word-wrap: break-word;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.export-link {
  margin-left: 10px;
  color: #19A043;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
}

.export-link:hover {
  text-decoration: underline;
}

.mb-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-container {
  display: flex;
  align-items: center;
}

.refresh-button {
  width: 80px;
  margin-bottom: 10px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  h2 {
    font-size: 18px;
    margin: 10px 0;
  }

  .export-link {
    font-size: 14px;
  }

  .table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .form-control {
    width: 100%;
  }

  .mb-3 {
    flex-direction: column;
    align-items: start;
  }

  .export-container {
    margin-top: 10px;
  }

  th, td {
    padding: 8px 10px;
  }

  .project-button {
    display: inline-block;
    margin: 5px 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    background-color: #00558E;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: calc(100% - 20px);
  }

  .project-button:hover {
    background-color: #003f63;
  }

  .item-separator {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
</style>
