import { createApp } from 'vue';


require('./bootstrap');


import App from './App.vue';
import axios from 'axios';
import router from './router';
import VueChartkick from 'vue-chartkick';
import 'chartkick/highcharts';


const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(router);
app.use(VueChartkick);
app.mount('#app');