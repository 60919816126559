<template>
  <div class="container">
    <div class="mb-3">
      <div class="row">
        <h2>Projektek</h2>
        <button @click="updateProjectItems" class="btn btn-primary refresh-button">Frissítés</button>
      </div>
    </div>
    <div class="row mb-3">
    </div>
    <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Projekt neve</th>
      <th scope="col">MX kód</th>
      <th scope="col">Lezárom</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(project, index) in openProjects" :key="project.id">
      <th scope="row">{{ index + 1 }}</th>
      <td>
        <input v-if="editing === project.id" v-model="project.name" class="form-control" @blur="saveProjectName(project.id, project)" />
        <span v-else @click="startEditing(project.id)">{{ project.name }}</span>
      </td>
      <td>
        <input v-model="project.name2" @focus="editing = project.id" @blur="saveName2(project.id, project)"
          class="form-control name2-input" />
        <button v-if="editing === project.id" class="btn btn-primary"
          @click="saveName2(project.id, project)">Mentés</button>
      </td>
      <!-- A módosított rész a "Lezárom" gomb megjelenítésével kapcsolatban: -->
      <td v-if="project.open && project.closeable && !closedProjectIds.includes(project.id)">
        <button class="btn btn-primary" @click="closeProject(project.id)">Lezárom</button>
      </td>
      <td v-else></td>
      <!-- Módosítás vége -->
      <td>{{ project.open ? '' : 'Lezárt' }}</td>
    </tr>
  </tbody>
</table>


    <div class="controls">
      <a @click="showClosed = !showClosed" style="cursor: pointer; color: blue; margin-left: auto; padding-right: 10px;">
        {{ showClosed ? "Rejtsd el a lezártakat" : "Mutasd a lezártakat" }}
      </a>
    </div>
    <confirm-modal :show="confirmClose" @update:show="confirmClose = $event" @confirm="doCloseProject"></confirm-modal>
  </div>
</template>


<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import ConfirmModal from '../components/ConfirmModal.vue';

export default {
  components: { ConfirmModal },
  setup() {
    const projects = ref([]);
    const showClosed = ref(false);
    const confirmClose = ref(false);
    const editing = ref(null);
    const tempName2 = ref('');
    const closedProjectIds = ref([]);

    let closeProjectId = null;

    const openProjects = computed(() => {
      return projects.value.filter(p => showClosed.value || p.open);
    });

    onMounted(async () => {
      try {
        const response = await axios.get('/api/projects');
        projects.value = response.data;
      } catch (error) {
        console.error(error);
      }
    });

    const closeProject = (projectId) => {
      closeProjectId = projectId;
      confirmClose.value = true;
    };

    const doCloseProject = async () => {
      try {
        await axios.post(`/api/projects/${closeProjectId}/close`);
        const project = projects.value.find(p => p.id === closeProjectId);
        if (project) {
          project.open = false;
        }
        confirmClose.value = false;
        closedProjectIds.value.push(closeProjectId);
      } catch (error) {
        console.error(error);
      }
    };

    const startEditing = (projectId) => {
      editing.value = projectId;
    };

    const saveProjectName = async (projectId, project) => {
      try {
        await axios.get('/sanctum/csrf-cookie');
        await axios.put(`/api/projects/${projectId}/updateName`, {
          name: project.name
        });
        editing.value = null;
      } catch (error) {
        console.error(error);
        alert('Nem sikerült frissíteni a projekt nevét!');
      }
    };

    const saveName2 = async (projectId, project) => {
      try {
        await axios.get('/sanctum/csrf-cookie');
        await axios.put(`/api/projects/${projectId}/updateName2`, {
          name2: project.name2
        });
        editing.value = null;
      } catch (error) {
        console.error(error);
        alert('Az elemek frissítése sikertelen volt!');
      }
    };

    const updateProjectItems = async () => {
      try {
        await axios.get('/sanctum/csrf-cookie');
        const openNonCloseableProjects = projects.value.filter(project => project.open && !project.closeable);
        const formattedItems = openNonCloseableProjects.map(project => project.name);
        const response = await axios.post('/api/updateProjectItems', { items: formattedItems });
        if (response.data.success) {
          alert('Az elemek frissítése sikeres volt!');
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        alert('Az elemek frissítése sikeres volt!');
      }
      try {
        await axios.get('/sanctum/csrf-cookie');
        const openNonCloseableProjects = projects.value.filter(project => project.open && !project.closeable);
        const formattedItems = openNonCloseableProjects.map(project => project.name);
        const response = await axios.post('/api/updateCloseableProjectItems', { items: formattedItems });
        if (response.data.success) {
          alert('Az elemek frissítése sikeres volt!');
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        alert('Az elemek frissítése sikeres volt!');
      }
    };

    return {
      projects, closeProject, showClosed, openProjects, confirmClose, updateProjectItems,
      editing, tempName2, startEditing, saveName2, saveProjectName, doCloseProject, closedProjectIds
    };
  },
};
</script>

<style scoped>
.add-item-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  padding-bottom: 80px;
}

.add-item-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: white;
  background-color: #19A043;
  border: none;
}

.sort-indicator {
  color: #00558E;
}

.container {
  padding-top: 2em;
}

.btn-primary {
  background-color: #19A043;
  border-color: #19A043;
}

th {
  color: #00558E;
  font-weight: bold;
}

.table th,
.table td {
  border: none;
  /* Eltávolítjuk az összes cellától a keretet */
}

/* Hozzáadjuk a keretet a 'name' input mezőkhöz */
table tbody tr td input[v-model="item.name"] {
  border: 1px solid #dee2e6;
}

.export-link {
  margin-left: 10px;
  color: #19A043;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
  /* This is to align it with the button vertically */
}

.export-link:hover {
  text-decoration: underline;
}

.mb-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-container {
  display: flex;
  align-items: center;
}

.refresh-button {
  width: 80px;
  margin-bottom: 10px;
  font-size: 14px;
}

.form-control {
  border: 1px solid #ced4da;
  /* Ez az alap Bootstrap input keret színe. */
  border-radius: 0.25rem;
  /* Bootstrap alapértelmezett border-radius */
}

.name2-input {
  width: 50%;
}
@media (max-width: 768px) {
    .mb-3 {
      flex-direction: column;
      align-items: flex-start;
    }

    .refresh-button {
      width: 100%;
      margin-top: 10px;
    }

    .export-container {
      margin-top: 10px;
    }

    .name2-input {
      width: 100%;
    }

    .table {
      display: block;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
    }

    .btn {
      padding: 10px 15px; /* Increase padding for touch friendliness */
    }
  }
</style>
