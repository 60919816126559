import { createWebHistory, createRouter } from "vue-router";

import Login from '../pages/Login';
import Register from '../pages/Register';
import Keszlet from '../pages/Keszlet';
import Projektek from '../pages/Projektek';
import Employees from '../pages/Employee';
import sales from '../pages/SalesMobil';
import Home from '../pages/Home';
import Eredmeny from '../pages/Eredmeny';
import EredmenyMobil from '../pages/EredmenyMobil';
import ToolList from '../pages/ToolList';

import AddItemComponent from '../pages/AddItemComponent';
import Xls from '../pages/XlsImport';

export const routes = [
    {
        name: 'Keszlet',
        path: '/Keszlet',
        //meta: { requiresAuth: true },
        component: Keszlet
    },
    {
        name: 'sales',
        path: '/sales',
        //meta: { requiresAuth: true },
        component: sales
    },
    {
        name: 'Xls',
        path: '/Xls',
        //meta: { requiresAuth: true },
        component: Xls
    },

    {
        name: 'home',
        path: '/',
        //meta: { requiresAuth: true },
        component: Home,
    },
    {
        name: 'eredmeny',
        path: '/eredmeny',
        //meta: { requiresAuth: true },
        component: Eredmeny,
    },
    {
        name: 'eredmenymobil',
        path: '/eredmenymobil',
        //meta: { requiresAuth: true },
        component: EredmenyMobil,
    },
    {
        name: 'projektek',
        path: '/projektek',
        //meta: { requiresAuth: true },
        component: Projektek,
    },
    {
        name: 'employees',
        path: '/employees',
        //meta: { requiresAuth: true },
        component: Employees,
    },
    {
        name: 'ToolList',
        path: '/ToolList',
        //meta: { requiresAuth: true },
        component: ToolList,
    },
    /*{
        name: 'register',
        path: '/register',
        component: Register
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },*/
    {
        name: 'urlapteszt',
        path: '/urlapteszt',
        //meta: { requiresAuth: true },
        component: AddItemComponent
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !window.Laravel.isLoggedin) {
        return next({ name: 'login' });
    }

    next();
})

export default router;
