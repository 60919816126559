<template>
    <div class="container-fluid p-0">
        <nav class="navbar navbar-expand-sm navbar-light ps-3 py-1">
            <router-link class="navbar-brand" to="/">Inputcsoport</router-link>
            <button class="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId"
                aria-expanded="false" aria-label="Toggle navigation"></button>
            
            <div class="navbar-nav">  <!--v-if="isLoggedIn"  -->
                <router-link to="/eredmeny" class="nav-item nav-link" v-bind:class="{ 'active-link': $route.path === '/eredmeny' }">Munkanaplók</router-link>
                <router-link to="/eredmenyMobil" class="nav-item nav-link" v-bind:class="{ 'active-link': $route.path === '/eredmenyMobil' }">Munkanaplók Mobil</router-link>
                <router-link to="/Keszlet" class="nav-item nav-link" v-bind:class="{ 'active-link': $route.path === '/Keszlet' }">Eszközök</router-link>
                <router-link to="/ToolList" class="nav-item nav-link" v-bind:class="{ 'active-link': $route.path === '/ToolList' }">Munkagépek</router-link>
                <router-link to="/Projektek" class="nav-item nav-link" v-bind:class="{ 'active-link': $route.path === '/Projektek' }">Projektek</router-link>
                <router-link to="/employees" class="nav-item nav-link" v-bind:class="{ 'active-link': $route.path === '/employees' }">Dolgozók</router-link>
                <router-link to="/sales" class="nav-item nav-link" v-bind:class="{ 'active-link': $route.path === '/sales' }">Értékesítés</router-link>
            </div>
            
            <!-- Külön div a Kijelentkezés gombnak 
            <div class="ms-auto" style="padding-right: 1rem;" v-if="isLoggedIn">
               <a class="nav-item nav-link" style="cursor: pointer;" @click="logout">Kijelentkezés</a>
            </div> -->
            
            <div class="navbar-nav" >   <!--v-else -->
              <!-- <router-link to="/login" class="nav-item nav-link">Belépés</router-link>-->
              <!-- <router-link to="/register" class="nav-item nav-link" v-bind:class="{ 'active-link': $route.path === '/register' }">Regisztráció</router-link>-->
            </div>
        </nav>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "App",
        data() {
            return {
                isLoggedIn: false,
            }
        },
        created() {
            if (window.Laravel.isLoggedin) {
                this.isLoggedIn = true
            }
        },
        methods: {
            logout(e) {
                e.preventDefault()
                this.$axios.get('/sanctum/csrf-cookie').then(response => {
                    this.$axios.post('/api/logout')
                    .then(response => {
                        if(response.data.success) {
                            window.location.href = "/"
                        } else {
                            console.log(response);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                })
            }
        },
    }
</script>

<style>
.navbar {
  background-color: #00558E;
}

.navbar-brand {
    color: #fff; 
}

.nav-link {
  color: #fff;
}

.active-link {
    color: #B0B0B0;
}

.navbar-nav {
    --bs-nav-link-color: #fff; 
    --bs-nav-link-hover-color: #B0B0B0;
    --bs-nav-link-active-color: #B0B0B0; 
}

.navbar-brand:hover, .navbar-brand:focus {
    color: #B0B0B0;
}

@media (max-width: 767.98px) {
    .navbar {
        display: none;
    }
}

</style>
