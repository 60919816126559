<template>
  <div class="container">
    <div class="mb-3">
      <div class="row">
        <h2>Munkagépek</h2>
        <button @click="updateTools" class="btn btn-primary refresh-button">Frissítés</button>
      </div>
      <div class="export-container">
        <a href="#" @click="exportToXLS" class="text-right export-link">Exportálás XLS-be</a>
      </div>
    </div>
      <div class="row mb-3">
        <input v-model="searchText" class="form-control" type="text" placeholder="Keresés...">
      </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" @click="changeSort('Cikkkód')">Cikkkód</th>
          <th scope="col" @click="changeSort('Megnevezés')">Megnevezés</th>
          <th scope="col" @click="changeSort('Mennyiség')">Mennyiség</th>
          <th scope="col" @click="changeSort('telephely')">Telephely</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tool, index) in filteredTools" :key="tool.id">
          <td><input v-model="tool.Cikkkód" class="form-control" type="text"></td>
          <td><input v-model="tool.Megnevezés" class="form-control" type="text"></td>
          <td><input v-model="tool.Mennyiség" class="form-control" type="text"></td>
          <td><input v-model="tool.telephely" class="form-control" type="text"></td>
          <td>
            <button v-if="toolChanged(index)" @click="saveTool(index)" class="btn btn-primary">Mentés</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="add-tool-container">
      <button @click="addTool" class="add-tool-button">+</button>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';

export default {
  name: 'EditableTable',
  data() {
    return {
      tools: [],
      originalTools: [],
      sortField: '',
      sortAscending: true,
      searchText: '',
    };
  },
  async created() {
    await this.fetchTools();
  },
  methods: {

    exportToXLS() {
      let ws = XLSX.utils.json_to_sheet(this.tools);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Munkagépek");
      XLSX.writeFile(wb, "munkagepek.xlsx");
    },

    async fetchTools() {
      try {
        await axios.get('/sanctum/csrf-cookie');
        const response = await axios.get('/api/tools');
        this.tools = response.data;
        this.originalTools = JSON.parse(JSON.stringify(this.tools));
      } catch (error) {
        console.error(error);
      }
    },

    addTool() {
      const newTool = {
        Cikkkód: '',
        Megnevezés: '',
        Mennyiség: '',
        telephely: '',
      };
      this.tools.push(newTool);
      this.originalTools.push(JSON.parse(JSON.stringify(newTool)));
    },

    async updateTools() {
      try {
        await axios.get('/sanctum/csrf-cookie');
        const formattedTools = this.tools.map(tool => `${tool.Megnevezés} (${tool.Mennyiség}db) ${tool.telephely}`);
        const response = await axios.post('/api/replaceUsedItems', { items: formattedTools });

        if (response.data.success) {
          alert('A munkagépek frissítése sikeres volt!');
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        alert('Hiba történt a munkagépek frissítése közben.');
      }
    },

    async saveTool(index) {
      try {
        await axios.get('/sanctum/csrf-cookie');
        const tool = this.tools[index];
        if (tool.id) {
          await axios.put(`/api/tools/${tool.id}`, tool);
        } else {
          const response = await axios.post('/api/tools', tool);
          this.tools[index].id = response.data.id;
        }
        this.originalTools[index] = JSON.parse(JSON.stringify(this.tools[index]));
      } catch (error) {
        console.error(error);
      }
    },

    toolChanged(index) {
      return JSON.stringify(this.tools[index]) !== JSON.stringify(this.originalTools[index]);
    },

    changeSort(field) {
      if (this.sortField === field) {
        this.sortAscending = !this.sortAscending;
      } else {
        this.sortField = field;
        this.sortAscending = true;
      }
    },
  },
  computed: {
    filteredTools() {
      let tools = [...this.tools];

      if (this.searchText) {
        tools = tools.filter(tool =>
          tool.Cikkkód.toLowerCase().includes(this.searchText.toLowerCase()) ||
          tool.Megnevezés.toLowerCase().includes(this.searchText.toLowerCase()) ||
          tool.telephely.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }

      if (this.sortField) {
        tools = tools.sort((a, b) => {
          if (a[this.sortField] < b[this.sortField]) return this.sortAscending ? -1 : 1;
          if (a[this.sortField] > b[this.sortField]) return this.sortAscending ? 1 : -1;
          return 0;
        });
      }

      return tools;
    },
  },
};
</script>
  
<style scoped>
.container {
  padding-top: 2em;
}

.refresh-button {
  margin-bottom: 10px;
}

.container {
  padding-top: 2em;
}

.btn-primary {
  background-color: #19A043;
  border-color: #19A043;
}

th {
  color: #00558E;
  font-weight: bold;
}

.table th,
.table td {
  border: 0;
}

.table th:nth-child(2),
.table td:nth-child(2) {
  width: 40%;
}

.add-tool-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  padding-bottom: 80px;
}

.add-tool-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: white;
  background-color: #19A043;
  border: none;
}

table {
  border-collapse: collapse;
}

th {
  color: #00558E;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.export-link {
  margin-left: 10px;
  color: #19A043;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
  /* This is to align it with the button vertically */
}

.export-link:hover {
  text-decoration: underline;
}
.mb-3 {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Elrendezzük az elemeket a konténer két végén */
}
.export-container {
  display: flex;
  align-items: center;
}

.refresh-button {
  width: 80px;
  margin-bottom: 10px;
  font-size: 14px;        /* Reduce the font-size */
  /*padding: 5px 10px;     /* Adjust the padding */
}

</style>
  