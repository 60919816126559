<template>
  <div>
    <h2>Dolgozók</h2>
    <button class="btn btn-primary refresh-button" @click="updateEmployeeItems">Frissítés</button>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Dolgozó neve</th>
          <th scope="col">Aktív</th>
          <th scope="col"></th> <!-- Új oszlop a törlés gomb számára -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(employee, index) in filteredEmployees" :key="employee.id">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ employee.name }}</td>
          <td>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" v-model="employee.active"
                @change="updateEmployeeStatus(employee)" />
            </div>
          </td>
          <td>
            <button v-if="!employee.active" class="btn btn-danger delete-button" @click="deleteEmployee(employee)">
              <img :src="'img/trash-outline.svg'" alt="Törlés" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="controls">
      <button v-if="!addingNewEmployee" class="btn btn-primary" @click="addNewEmployee">Új dolgozó hozzáadása</button>
      <a @click="showInactive = !showInactive"
        style="cursor: pointer; color: blue; margin-left: auto; padding-right: 10px;">
        {{ showInactive ? "Rejtsd el az inaktívakat" : "Mutasd az inaktívakat" }}
      </a>
    </div>
    <div v-if="addingNewEmployee" style="margin-top: 10px;">
      <input type="text" v-model="newEmployeeName" placeholder="Dolgozó neve" class="form-control" />
      <button class="btn btn-primary" @click="saveNewEmployee">Mentés</button>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const employees = ref([]);
    const newEmployeeName = ref('');
    const addingNewEmployee = ref(false);
    const showInactive = ref(false);

    const filteredEmployees = computed(() => {
      return employees.value.filter(e => showInactive.value || e.active);
    });

    onMounted(async () => {
      try {
        const response = await axios.get('/api/employees');
        employees.value = response.data.map(employee => ({
          ...employee,
          active: !!employee.active,
        }));
      } catch (error) {
        console.error(error);
      }
    });

    const updateEmployeeStatus = async (employee) => {
      try {
        await axios.put(`/api/employees/${employee.id}`, employee);
      } catch (error) {
        console.error(error);
        employee.active = !employee.active;
      }
    };

    const addNewEmployee = () => {
      addingNewEmployee.value = true;
    };

    const saveNewEmployee = async () => {
      if (newEmployeeName.value) {
        try {
          const response = await axios.post('/api/employees', { name: newEmployeeName.value });
          employees.value.push(response.data.employee);
          newEmployeeName.value = '';
          addingNewEmployee.value = false;
        } catch (error) {
          console.error(error);
        }
      }
    };

    const deleteEmployee = async (employee) => {
      try {
        await axios.delete(`/api/employees/${employee.id}`);
        const index = employees.value.findIndex(e => e.id === employee.id);
        if (index > -1) {
          employees.value.splice(index, 1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const updateEmployeeItems = async () => {
      try {
        const activeEmployees = employees.value.filter(employee => employee.active);
        const names = activeEmployees.map(employee => employee.name);
        const response = await axios.post('/api/updateEmployeeItems', { items: names });

        if (response.data.success) {
          alert('Az elemek frissítése sikeres volt!');
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        alert('Hiba történt az elemek frissítése közben.');
      }
    };


    return { employees, filteredEmployees, updateEmployeeStatus, addNewEmployee, newEmployeeName, addingNewEmployee, saveNewEmployee, showInactive, deleteEmployee, updateEmployeeItems };
  },
};
</script>

<style>
.btn-primary,
.btn-info {
  margin: 10px;
}

.container {
  padding-top: 2em;
}

.controls {
  display: flex;
  margin: 10px 0;
}

.delete-button {
  width: 20px;
  color: white;
  padding: 0px;
  /* állítsd be a padding méretét az igényeidnek megfelelően */
}

.delete-button img {
  filter: invert(1);
  /* fehér színűvé teszi az ikont */
}
</style>
